// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Models from "./Models.mjs";
import * as Parser from "../../model/src/Parser.mjs";
import * as Sentry from "./externals/Sentry.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Printer from "../../model/src/Printer.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Workspace from "../../model/src/Workspace.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ActionsReducer from "./actionsReducer.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as NamespaceIndex from "../../shared/models/NamespaceIndex.mjs";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as FirebaseFetchers from "./FirebaseFetchers.mjs";
import * as BranchStatusModel from "../../shared/models/BranchStatusModel.mjs";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";
import * as BranchStateReducer from "../../shared/BranchStateReducer.mjs";
import * as App from "firebase/compat/app";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as NamespaceIndexWriter from "./NamespaceIndexWriter.mjs";
import * as BranchStateFirebaseModel from "../../shared/models/BranchStateFirebaseModel.mjs";

var IllegalOperationCloseBranchAlreadyClosed = /* @__PURE__ */Caml_exceptions.create("BranchStateWriter.IllegalOperationCloseBranchAlreadyClosed");

var IllegalOperationCloseBranchAlreadyMerged = /* @__PURE__ */Caml_exceptions.create("BranchStateWriter.IllegalOperationCloseBranchAlreadyMerged");

var IllegalOperationMergeBranchAlreadyClosed = /* @__PURE__ */Caml_exceptions.create("BranchStateWriter.IllegalOperationMergeBranchAlreadyClosed");

var IllegalOperationMergeBranchAlreadyMerged = /* @__PURE__ */Caml_exceptions.create("BranchStateWriter.IllegalOperationMergeBranchAlreadyMerged");

var IllegalOperationBranchInitAlreadyCreated = /* @__PURE__ */Caml_exceptions.create("BranchStateWriter.IllegalOperationBranchInitAlreadyCreated");

function encodeFirebaseType(branchModel) {
  var lastCodegenBySource = branchModel.lastCodegenBySource;
  return {
          schemaId: Js_null_undefined.fromOption(branchModel.schemaId),
          branchId: branchModel.branchId,
          lastActivityDate: BranchStateFirebaseModel.encodeOptional(Belt_Option.mapU(branchModel.lastActivityDate, (function (date) {
                      return App.default.firestore.Timestamp.fromDate(date);
                    })), branchModel.isShallow),
          lastActiveUserId: BranchStateFirebaseModel.encodeOptional(branchModel.lastActiveUserId, branchModel.isShallow),
          branchStatus: BranchStateFirebaseModel.branchStatusToJs(branchModel.branchStatus),
          approvalsCount: BranchStateFirebaseModel.encodeOptional(branchModel.approvalsCount, branchModel.isShallow),
          creatorUserId: branchModel.creatorUserId,
          impactedSourceIds: BranchStateFirebaseModel.encodeOptional(branchModel.impactedSourceIds, branchModel.isShallow),
          creationDate: App.default.firestore.Timestamp.fromDate(branchModel.creationDate),
          branchName: branchModel.branchName,
          branchLongName: branchModel.branchLongName,
          commentsCount: BranchStateFirebaseModel.encodeOptional(branchModel.commentsCount, branchModel.isShallow),
          collaboratorIds: BranchStateFirebaseModel.encodeOptional(branchModel.collaboratorIds, branchModel.isShallow),
          reviewerIds: BranchStateFirebaseModel.encodeOptional(branchModel.reviewerIds, branchModel.isShallow),
          lastCodegenBySource: lastCodegenBySource !== undefined ? Js_dict.fromArray(Belt_MapString.toArray(Belt_MapString.mapU(Caml_option.valFromOption(lastCodegenBySource), (function (lastCodegenInfo) {
                            return {
                                    lastCodegenDate: App.default.firestore.Timestamp.fromDate(lastCodegenInfo.lastCodegenDate),
                                    lastCodegenUserId: lastCodegenInfo.lastCodegenUserId
                                  };
                          })))) : (
              branchModel.isShallow ? undefined : null
            ),
          lastPublishedAt: BranchStateFirebaseModel.encodeOptional(Belt_Option.mapU(branchModel.lastPublishedAt, (function (date) {
                      return App.default.firestore.Timestamp.fromDate(date);
                    })), branchModel.isShallow),
          isShallow: branchModel.isShallow,
          globalBranchId: BranchStateFirebaseModel.encodeOptional(branchModel.globalBranchId, branchModel.isShallow),
          eventWorkbenchOrder: BranchStateFirebaseModel.encodeOptional(branchModel.eventWorkbenchOrder, branchModel.isShallow)
        };
}

function exceptionToMessage(exceptionType) {
  if (exceptionType.RE_EXN_ID === IllegalOperationCloseBranchAlreadyClosed) {
    return "Couldn't close branch because branch is already closed";
  } else if (exceptionType.RE_EXN_ID === IllegalOperationCloseBranchAlreadyMerged) {
    return "Couldn't close branch because branch is merged";
  } else if (exceptionType.RE_EXN_ID === IllegalOperationMergeBranchAlreadyClosed) {
    return "Couldn't merge branch because branch is closed";
  } else if (exceptionType.RE_EXN_ID === IllegalOperationMergeBranchAlreadyMerged) {
    return "Couldn't merge branch because branch is already merged";
  } else if (exceptionType.RE_EXN_ID === IllegalOperationBranchInitAlreadyCreated) {
    return "Couldn't create branch because branch already exists";
  } else {
    return "Unknown error occurred";
  }
}

function isBranchStatusUpdateAction(action) {
  if (typeof action !== "object") {
    return false;
  }
  var variant = action.NAME;
  if (variant === "BranchInit" || variant === "CloseBranch" || variant === "SetBranchStatus") {
    return true;
  } else {
    return variant === "MergeBranch";
  }
}

function wantsToUpdateBranchStatus(actions) {
  return Belt_Array.someU(actions, (function (param) {
                return isBranchStatusUpdateAction(param[0]);
              }));
}

function buildBranchStateRef(schemaId, branchId) {
  return Firebase.app().firestore().collection("schemas").doc(schemaId).collection("branchStates").doc(branchId);
}

function buildFullBranchState(schemaId, branchId, masterModel, branchModel) {
  var actionsRef = Firebase.app().firestore().collection("schemas").doc(schemaId).collection("actions");
  return Promise.all([
                  actionsRef.where("contentsJson.OpenBranch.branchId", "==", branchId).get(),
                  actionsRef.where("contentsJson.MergeBranch.branchId", "==", branchId).get(),
                  actionsRef.where("contentsJson.CloseBranch.branchId", "==", branchId).get(),
                  actionsRef.where("contentsJson.SetBranchStatus.branchId", "==", branchId).limit(Workspace.maxRequiredApprovals).get(),
                  Firebase.app().firestore().collection("schemas").doc(schemaId).collection("actions").orderBy("createdAt", "desc").limit(100).get(),
                  Firebase.app().firestore().collection("schemas").doc(schemaId).collection("collaboration").doc(branchId).collection("collaborators").where("removedAt", "==", null).get()
                ]).then(function (param) {
                var collaboratorsSnapshot = param[5];
                var actionsSnapshot = param[4];
                var setBranchStatusQuerySnapshot = param[3];
                var closeBranchQuerySnapshot = param[2];
                var mergeBranchQuerySnapshot = param[1];
                var openBranchQuerySnapshot = param[0];
                return Promise.all([
                              FirebaseFetchers.fetchAllCommentActionsOnBranch(schemaId, branchId),
                              FirebaseFetchers.fetchDeletedCommentActionsOnBranch(schemaId, branchId)
                            ]).then(function (param) {
                            return Promise.resolve([
                                        openBranchQuerySnapshot,
                                        mergeBranchQuerySnapshot,
                                        closeBranchQuerySnapshot,
                                        setBranchStatusQuerySnapshot,
                                        actionsSnapshot,
                                        collaboratorsSnapshot,
                                        param[0],
                                        param[1]
                                      ]);
                          });
              }).then(function (param) {
              var maybeOpenBranchAction = Belt_Option.map(Belt_Array.get(param[0].docs, 0), (function (prim) {
                      return prim.data();
                    }));
              var openBranchAction;
              if (maybeOpenBranchAction !== undefined) {
                openBranchAction = Caml_option.valFromOption(maybeOpenBranchAction);
              } else {
                throw Js_exn.raiseError("Can't find branch with id " + branchId);
              }
              var match = Parser.parseAction(openBranchAction.contentsJson);
              var branchName = typeof match === "object" && match.NAME === "OpenBranch" ? match.VAL[1] : Pervasives.failwith("Failed to parse branch name");
              var createdAt = Belt_Option.getExn(Models.toDateOption(openBranchAction.createdAt));
              var creatorUserId = openBranchAction.createdBy;
              var maybeMergeAction = Belt_Array.get(Belt_Array.map(param[1].docs, (function (prim) {
                          return prim.data();
                        })), 0);
              var maybeCloseAction = Belt_Array.get(Belt_Array.map(param[2].docs, (function (prim) {
                          return prim.data();
                        })), 0);
              var setBranchStatusActions = Belt_List.fromArray(Belt_Array.map(param[3].docs, (function (doc) {
                          return FirebaseFetcherHooks.transformAction(doc.data());
                        })));
              var branchStatus = BranchStatusModel.get(openBranchAction, maybeMergeAction, maybeCloseAction, setBranchStatusActions);
              var collaborators = Belt_Array.map(param[5].docs, (function (prim) {
                      return prim.data();
                    }));
              var collaboratorIds = Belt_Array.map(collaborators, (function (prim) {
                      return prim.id;
                    }));
              var reviewerIds = Belt_Array.keepMap(collaborators, (function (c) {
                      if (Caml_obj.equal(Caml_option.undefined_to_opt(c.isReviewer), true)) {
                        return c.id;
                      }
                      
                    }));
              var actions = Belt_Array.map(Belt_Array.map(param[4].docs, (function (prim) {
                          return prim.data();
                        })), FirebaseFetcherHooks.transformAction);
              var lastAction = Belt_Array.getExn(Belt_Array.keepU(actions, (function (action) {
                          var match = action.contents;
                          if (typeof match !== "object") {
                            return match === "ResetTrackingPlan";
                          }
                          var variant = match.NAME;
                          return !(variant === "RemoveMember" || variant === "CollaboratorRemoved" || variant === "CreateServiceAccount" || variant === "AutoPublishIntegration" || variant === "ArchiveSavedView" || variant === "CodeGenerated" || variant === "UpdateGlobalRequirements" || variant === "ResendInvite" || variant === "CreateInspectorIssue" || variant === "PropertyComment" || variant === "DeleteComment" || variant === "SubscriptionStarted" || variant === "PublishIntegration" || variant === "SubscriptionCancelled" || variant === "SubscriptionUpdated" || variant === "GenerateWebhookSecret" || variant === "ReviewerAdded" || variant === "UpdateMemberRole" || variant === "GoalComment" || variant === "UpdateSavedView" || variant === "IntegrationComment" || variant === "InspectorIssueComment" || variant === "MigrateExcludeEventsToIncludeEvents" || variant === "DeleteServiceAccount" || variant === "MetricComment" || variant === "CollaboratorAdded" || variant === "PropertyGroupComment" || variant === "AddMigrationDangerous" || variant === "UpdateInspectorIssueStatus" || variant === "EventComment" || variant === "OpenBranch" || variant === "RemoveInvite" || variant === "BranchComment" || variant === "InviteMember" || variant === "ReviewerRemoved" || variant === "MigrateSourcesAndDestinationsToModel" || variant === "PaymentCompleted" || variant === "CreateSavedView");
                        })), 0);
              var lastActionBy = lastAction.createdBy;
              var lastActionAt = lastAction.createdAt;
              var lastCodegenBySource = Belt_Array.concatMany(Belt_Array.map(actions, (function (action) {
                          var match = action.contents;
                          if (typeof match !== "object") {
                            return [];
                          }
                          if (match.NAME !== "CodeGenerated") {
                            return [];
                          }
                          var match$1 = match.VAL;
                          if (match$1[0] === branchId) {
                            return Belt_List.toArray(Belt_List.mapU(match$1[1], (function (sourceId) {
                                              return [
                                                      sourceId,
                                                      {
                                                        lastCodegenDate: Belt_Option.getExn(Models.toDateOption(action.createdAt)),
                                                        lastCodegenUserId: action.createdBy
                                                      }
                                                    ];
                                            })));
                          } else {
                            return [];
                          }
                        })));
              var successfulPublishAction = Belt_Array.getBy(actions, (function (action) {
                      var match = action.contents;
                      if (typeof match !== "object") {
                        return false;
                      }
                      var variant = match.NAME;
                      if (variant === "AutoPublishIntegration" || variant === "PublishIntegration") {
                        return match.VAL[1];
                      } else {
                        return false;
                      }
                    }));
              var lastSuccessfulPublishAt = Belt_Option.map(successfulPublishAction, (function (successfulPublishAction) {
                      return successfulPublishAction.createdAt;
                    }));
              var impactedSourceIds = BranchStateReducer.changedSourceIds(branchModel, masterModel);
              var comments = Belt_Array.map(Belt_Array.concatMany(Belt_Array.map(param[6], (function (prim) {
                              return prim.docs;
                            }))), (function (prim) {
                      return prim.data();
                    }));
              var commentDeletions = Belt_Array.map(param[7].docs, (function (prim) {
                      return prim.data();
                    }));
              var commentsWithoutDeleted = Belt_Array.keepU(comments, (function (commentAction) {
                      var match = Parser.parseAction(commentAction.contentsJson);
                      if (typeof match !== "object") {
                        return false;
                      }
                      var variant = match.NAME;
                      if (!(variant === "PropertyComment" || variant === "GoalComment" || variant === "IntegrationComment" || variant === "InspectorIssueComment" || variant === "MetricComment" || variant === "PropertyGroupComment" || variant === "EventComment" || variant === "BranchComment")) {
                        return false;
                      }
                      var commentId = match.VAL[1];
                      return Belt_Array.everyU(commentDeletions, (function (deletedCommentAction) {
                                    var match = Parser.parseAction(deletedCommentAction.contentsJson);
                                    if (typeof match === "object" && match.NAME === "DeleteComment") {
                                      return commentId !== match.VAL[1];
                                    } else {
                                      return true;
                                    }
                                  }));
                    }));
              var numComments = commentsWithoutDeleted.length;
              var approvalsCount = BranchStatusModel.branchApprovalsCount(branchStatus);
              return Promise.resolve({
                          schemaId: schemaId,
                          branchId: branchId,
                          lastActivityDate: Models.toDateOption(lastActionAt),
                          lastActiveUserId: lastActionBy,
                          branchStatus: BranchStateFirebaseModel.branchStatusModelToBranchStateBranchStatus(branchStatus),
                          approvalsCount: approvalsCount,
                          creatorUserId: creatorUserId,
                          impactedSourceIds: impactedSourceIds,
                          creationDate: createdAt,
                          branchName: branchName,
                          branchLongName: branchName,
                          commentsCount: numComments,
                          collaboratorIds: collaboratorIds,
                          reviewerIds: reviewerIds,
                          lastCodegenBySource: Caml_option.some(Belt_MapString.fromArray(lastCodegenBySource)),
                          lastPublishedAt: Belt_Option.flatMapU(lastSuccessfulPublishAt, Models.toDateOption),
                          isShallow: false,
                          globalBranchId: undefined,
                          eventWorkbenchOrder: []
                        });
            });
}

function refreshFullBranchState(schemaId, branchId, branchModel, masterModel) {
  var branchStateRef = buildBranchStateRef(schemaId, branchId);
  var buildBranchStatePromise = buildFullBranchState(schemaId, branchId, masterModel, branchModel);
  return $$Promise.$$catch(buildBranchStatePromise.then(function (branchState) {
                    return branchStateRef.set(encodeFirebaseType(branchState), {"merge": true});
                  }).then(function (param) {
                  return Promise.resolve((console.log("Successfully updated branch state"), undefined));
                }), (function (e) {
                return Promise.resolve((console.log(e), undefined));
              }));
}

function getOldBranchState(docAlreadyExists, branchStateDoc, impactedBranchActions, shouldUpdate, masterModel, branchModel, schemaId, impactedBranchId, creatorUserId) {
  var hasBranchInitAction = Belt_Array.some(impactedBranchActions, (function (action) {
          if (typeof action !== "object") {
            return false;
          }
          var variant = action.NAME;
          if (variant !== "CreateDemoBranch") {
            return variant === "BranchInit";
          }
          var match = action.VAL;
          if (!match) {
            return false;
          }
          var match$1 = match.hd;
          if (typeof match$1 === "object") {
            return match$1.NAME === "BranchInit";
          } else {
            return false;
          }
        }));
  if (docAlreadyExists) {
    var docData = branchStateDoc.data();
    var currentBranchState = BranchStateFirebaseModel.parseFirebaseType(docData);
    var maybeIllegalOperation = Belt_Array.get(Belt_Array.keepMapU(impactedBranchActions, (function (action) {
                var match = currentBranchState.branchStatus;
                if (typeof action !== "object") {
                  return ;
                }
                var variant = action.NAME;
                if (variant === "MergeBranch") {
                  if (match === "Closed") {
                    return {
                            RE_EXN_ID: IllegalOperationMergeBranchAlreadyClosed
                          };
                  } else if (match === "Merged") {
                    return {
                            RE_EXN_ID: IllegalOperationMergeBranchAlreadyMerged
                          };
                  } else {
                    return ;
                  }
                } else if (variant === "CloseBranch") {
                  if (match === "Closed") {
                    return {
                            RE_EXN_ID: IllegalOperationCloseBranchAlreadyClosed
                          };
                  } else if (match === "Merged") {
                    return {
                            RE_EXN_ID: IllegalOperationCloseBranchAlreadyMerged
                          };
                  } else {
                    return ;
                  }
                } else if (variant === "BranchInit") {
                  return {
                          RE_EXN_ID: IllegalOperationBranchInitAlreadyCreated
                        };
                } else {
                  return ;
                }
              })), 0);
    if (maybeIllegalOperation !== undefined) {
      return Promise.reject(maybeIllegalOperation);
    } else if (currentBranchState.isShallow) {
      shouldUpdate.contents = true;
      return buildFullBranchState(schemaId, impactedBranchId, masterModel, branchModel);
    } else {
      return Promise.resolve(currentBranchState);
    }
  }
  if (hasBranchInitAction) {
    shouldUpdate.contents = true;
    var branchName = Belt_Option.getExn(Belt_Array.get(Belt_Array.keepMap(impactedBranchActions, (function (action) {
                    if (typeof action !== "object") {
                      return ;
                    }
                    var variant = action.NAME;
                    if (variant !== "CreateDemoBranch") {
                      if (variant === "BranchInit") {
                        return action.VAL[1];
                      } else {
                        return ;
                      }
                    }
                    var match = action.VAL;
                    if (!match) {
                      return ;
                    }
                    var match$1 = match.hd;
                    if (typeof match$1 === "object" && match$1.NAME === "BranchInit") {
                      return match$1.VAL[1];
                    }
                    
                  })), 0));
    return Promise.resolve({
                schemaId: schemaId,
                branchId: impactedBranchId,
                lastActivityDate: Caml_option.some(new Date()),
                lastActiveUserId: creatorUserId,
                branchStatus: "Draft",
                approvalsCount: undefined,
                creatorUserId: creatorUserId,
                impactedSourceIds: [],
                creationDate: new Date(),
                branchName: branchName,
                branchLongName: branchName,
                commentsCount: 0,
                collaboratorIds: [creatorUserId],
                reviewerIds: [],
                lastCodegenBySource: undefined,
                lastPublishedAt: undefined,
                isShallow: false,
                globalBranchId: undefined,
                eventWorkbenchOrder: []
              });
  }
  shouldUpdate.contents = true;
  return buildFullBranchState(schemaId, impactedBranchId, masterModel, branchModel);
}

function writeBranchStateToFirestoreFromActionsInTransaction(schemaId, branchId, currentBranchId, actions, creatorUserId, branchModelBeforeActions, mappedBranchModelBeforeActions, masterModel, mappedMasterModel, additionalWriteOps) {
  var masterModel$1 = branchId === "master" ? branchModelBeforeActions : masterModel;
  var branchModel = Belt_Array.reduceU(actions, branchModelBeforeActions, (function (newBranchModel, param) {
          return ActionsReducer.reduce(newBranchModel, param[0]);
        }));
  var mappedBranchModel = Belt_Array.reduceU(actions, mappedBranchModelBeforeActions, (function (newMappedBranchModel, param) {
          return ActionsReducer.reduceMapped(newMappedBranchModel, param[0]);
        }));
  var impactedBranchIds = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concat(Belt_Array.keepMap(actions, (function (param) {
                      var action = param[0];
                      if (typeof action !== "object") {
                        return ;
                      }
                      var variant = action.NAME;
                      if (variant === "BranchInit" || variant === "CloseBranch" || variant === "SetBranchStatus" || variant === "MergeBranch") {
                        return action.VAL[0];
                      }
                      
                    })), branchId === "master" ? [] : [branchId])));
  return $$Promise.$$catch(Firebase.app().firestore().runTransaction(function (transaction) {
                  var branchStateUpdatePromises = Promise.all(Belt_Array.map(impactedBranchIds, (function (impactedBranchId) {
                              var impactedBranchActions = Belt_Array.keepMap(actions, (function (param) {
                                      if (Caml_obj.equal(Caml_option.undefined_to_opt(param[1].branchId), impactedBranchId)) {
                                        return param[0];
                                      }
                                      
                                    }));
                              var branchStateRef = buildBranchStateRef(schemaId, impactedBranchId);
                              return transaction.get(branchStateRef).then(function (branchStateDoc) {
                                          var shouldUpdate = {
                                            contents: false
                                          };
                                          var docAlreadyExists = branchStateDoc.exists;
                                          var isTrackingPlanReset = Belt_Array.some(actions, (function (action) {
                                                  return action[0] === "ResetTrackingPlan";
                                                }));
                                          return getOldBranchState(docAlreadyExists, branchStateDoc, impactedBranchActions, shouldUpdate, masterModel$1, branchModel, schemaId, impactedBranchId, creatorUserId).then(function (oldBranchState) {
                                                      var newBranchState = Belt_Array.reduceU(actions, oldBranchState, (function (newBranchState, param) {
                                                              var match = BranchStateReducer.reduce(newBranchState, param[0], creatorUserId, new Date(), isTrackingPlanReset ? undefined : branchModel, param[1], isTrackingPlanReset ? undefined : masterModel$1);
                                                              if (match[1] === true) {
                                                                shouldUpdate.contents = true;
                                                                return match[0];
                                                              } else {
                                                                return newBranchState;
                                                              }
                                                            }));
                                                      if (shouldUpdate.contents) {
                                                        return Promise.resolve([
                                                                    branchStateRef,
                                                                    encodeFirebaseType(newBranchState)
                                                                  ]);
                                                      } else {
                                                        return Promise.resolve(undefined);
                                                      }
                                                    });
                                        });
                            })));
                  var wantsToUpdateNamespaceIndex = NamespaceIndexWriter.Web.wantsToUpdateNamespaceIndex(branchId, currentBranchId, actions);
                  var branchMergeOrCloseActions = Belt_Array.keep(actions, (function (param) {
                          var action = param[0];
                          if (typeof action !== "object") {
                            return false;
                          }
                          var variant = action.NAME;
                          if (variant === "CloseBranch") {
                            return true;
                          } else {
                            return variant === "MergeBranch";
                          }
                        }));
                  var match;
                  if (wantsToUpdateNamespaceIndex) {
                    if (branchMergeOrCloseActions.length !== 0) {
                      var impactedBranchId = Belt_Array.getExn(Belt_Array.keepMap(branchMergeOrCloseActions, (function (param) {
                                  var action = param[0];
                                  if (typeof action !== "object") {
                                    return ;
                                  }
                                  var variant = action.NAME;
                                  if (variant === "CloseBranch" || variant === "MergeBranch") {
                                    return action.VAL[0];
                                  }
                                  
                                })), 0);
                      var removeFromIndexPromise = Promise.all(Belt_Array.map(NamespaceIndexWriter.getNamespaceIndexesToUpdate(branchMergeOrCloseActions), (function (itemType) {
                                  var namespaceIndexRef = Firebase.app().firestore().collection("schemas").doc(schemaId).collection("namespaceIndex").doc(NamespaceIndex.ItemType.toString(itemType));
                                  return transaction.get(namespaceIndexRef).then(function (namespaceIndexItemSnapshot) {
                                              var maybeNameSpaceIndexDoc = namespaceIndexItemSnapshot.exists ? namespaceIndexItemSnapshot.data() : undefined;
                                              return [
                                                      namespaceIndexRef,
                                                      NamespaceIndexWriter.updateNamespaceIndex(impactedBranchId, itemType, maybeNameSpaceIndexDoc, mappedMasterModel, mappedBranchModel, true)
                                                    ];
                                            });
                                })));
                      match = [
                        Promise.resolve([]),
                        removeFromIndexPromise
                      ];
                    } else {
                      var updateNamespaceIndexesPromise = Promise.all(Belt_Array.map(NamespaceIndexWriter.getNamespaceIndexesToUpdate(actions), (function (itemType) {
                                  var namespaceIndexRef = Firebase.app().firestore().collection("schemas").doc(schemaId).collection("namespaceIndex").doc(NamespaceIndex.ItemType.toString(itemType));
                                  return Promise.resolve([
                                              namespaceIndexRef,
                                              NamespaceIndexWriter.updateNamespaceIndex(branchId, itemType, undefined, mappedMasterModel, mappedBranchModel, false)
                                            ]);
                                })));
                      match = [
                        updateNamespaceIndexesPromise,
                        Promise.resolve([])
                      ];
                    }
                  } else {
                    match = [
                      Promise.resolve([]),
                      Promise.resolve([])
                    ];
                  }
                  return Promise.all([
                                branchStateUpdatePromises,
                                match[0],
                                match[1]
                              ]).then(function (param) {
                              var transaction$1 = Belt_Array.reduceU(additionalWriteOps, transaction, (function (transaction, param) {
                                      return transaction.set(param[0], param[1]);
                                    }));
                              var transaction$2 = Belt_Array.reduceU(param[0], transaction$1, (function (transaction, maybeBranchStateUpdate) {
                                      if (maybeBranchStateUpdate !== undefined) {
                                        return transaction.set(maybeBranchStateUpdate[0], maybeBranchStateUpdate[1], {"merge": true});
                                      } else {
                                        return transaction;
                                      }
                                    }));
                              var transaction$3 = Belt_Array.reduceU(param[1], transaction$2, (function (transaction, param) {
                                      return transaction.set(param[0], param[1], {"merge": true});
                                    }));
                              Belt_Array.reduceU(param[2], transaction$3, (function (transaction, param) {
                                      return transaction.set(param[0], param[1]);
                                    }));
                              return Promise.resolve();
                            });
                }), (function (error) {
                var exceptionType;
                if (error.RE_EXN_ID === IllegalOperationCloseBranchAlreadyClosed || error.RE_EXN_ID === IllegalOperationCloseBranchAlreadyMerged || error.RE_EXN_ID === IllegalOperationMergeBranchAlreadyClosed || error.RE_EXN_ID === IllegalOperationMergeBranchAlreadyMerged || error.RE_EXN_ID === IllegalOperationBranchInitAlreadyCreated) {
                  exceptionType = error;
                } else {
                  Sentry.captureException(new Error("Failed to write branch state transaction: " + Belt_Option.getWithDefault(JSON.stringify(error), "N/A")), {
                        error: error,
                        schemaId: schemaId,
                        branchId: branchId,
                        userId: creatorUserId,
                        numWriteOps: additionalWriteOps.length,
                        headAction: Belt_Option.map(Belt_Array.get(actions, 0), (function (param) {
                                var partial_arg = Printer.printAction(param[0]);
                                return function (param) {
                                  return JSON.stringify(partial_arg, null, param);
                                };
                              }))
                      });
                  return Promise.resolve((console.error("Failed to write branch state transaction: ", Belt_Option.getWithDefault(JSON.stringify(error), "N/A"), error), undefined));
                }
                var errorMessage = "Illegal branch state change prevented: " + exceptionToMessage(exceptionType);
                return Promise.resolve((console.error(errorMessage), undefined));
              }));
}

export {
  IllegalOperationCloseBranchAlreadyClosed ,
  IllegalOperationCloseBranchAlreadyMerged ,
  IllegalOperationMergeBranchAlreadyClosed ,
  IllegalOperationMergeBranchAlreadyMerged ,
  IllegalOperationBranchInitAlreadyCreated ,
  encodeFirebaseType ,
  exceptionToMessage ,
  isBranchStatusUpdateAction ,
  wantsToUpdateBranchStatus ,
  buildBranchStateRef ,
  buildFullBranchState ,
  refreshFullBranchState ,
  getOldBranchState ,
  writeBranchStateToFirestoreFromActionsInTransaction ,
}
/* Models Not a pure module */
