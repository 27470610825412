// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as React$1 from "motion/react";

function container(hasCollapsedHeight, collapsed, faderColor, duration, withFade, containerStyles) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, {
                    hd: Css.overflowY("hidden"),
                    tl: {
                      hd: Css.position("relative"),
                      tl: {
                        hd: hasCollapsedHeight ? Css.after({
                                hd: Css.position("absolute"),
                                tl: {
                                  hd: Css.unsafe("content", "' '"),
                                  tl: {
                                    hd: Css.width(Css.pct(100.0)),
                                    tl: {
                                      hd: Css.pointerEvents("none"),
                                      tl: {
                                        hd: Css.display(hasCollapsedHeight ? "block" : "none"),
                                        tl: {
                                          hd: Css.opacity(collapsed ? 1.0 : 0.0),
                                          tl: {
                                            hd: Css.bottom(Css.px(0)),
                                            tl: {
                                              hd: Css.left(Css.px(0)),
                                              tl: {
                                                hd: Css.height(Css.px(24)),
                                                tl: {
                                                  hd: Css.transition(duration, undefined, undefined, "opacity"),
                                                  tl: {
                                                    hd: withFade ? Css.background({
                                                            NAME: "linearGradient",
                                                            VAL: [
                                                              {
                                                                NAME: "deg",
                                                                VAL: 180.0
                                                              },
                                                              [
                                                                [
                                                                  Css.pct(0.0),
                                                                  Styles.Color.setAlpha(faderColor, 0.0)
                                                                ],
                                                                [
                                                                  Css.pct(100.0),
                                                                  Styles.Color.setAlpha(faderColor, 1.0)
                                                                ]
                                                              ]
                                                            ]
                                                          }) : Styles.emptyStyle,
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }) : Styles.emptyStyle,
                        tl: /* [] */0
                      }
                    }
                  }),
              tl: {
                hd: containerStyles,
                tl: /* [] */0
              }
            });
}

var Style = {
  container: container
};

function getDurationToMs(duration) {
  if (typeof duration === "object") {
    return duration.VAL;
  } else if (duration === "short") {
    return Styles.Duration.$$short;
  } else if (duration === "default") {
    return Styles.Duration.$$default;
  } else {
    return Styles.Duration.$$long;
  }
}

function durationToSeconds(duration) {
  return getDurationToMs(duration) / 1000.0;
}

function getTransition(transition, duration) {
  if (transition === "default") {
    return {
            duration: getDurationToMs(duration) / 1000.0
          };
  } else {
    return {
            type: "spring",
            stiffness: 250,
            damping: 30,
            mass: 1
          };
  }
}

var use = Hooks.useDisclosure;

function Collapsible$Collapsible(Props) {
  var id = Props.id;
  var collapsedOpt = Props.collapsed;
  var collapsedHeightOpt = Props.collapsedHeight;
  var containerStylesOpt = Props.containerStyles;
  var durationOpt = Props.duration;
  var faderColorOpt = Props.faderColor;
  var loadingStyleOpt = Props.loadingStyle;
  var onAnimationComplete = Props.onAnimationComplete;
  var onAnimationStart = Props.onAnimationStart;
  var onChange = Props.onChange;
  var transitionOpt = Props.transition;
  var withFadeOpt = Props.withFade;
  var children = Props.children;
  var collapsed = collapsedOpt !== undefined ? collapsedOpt : true;
  var collapsedHeight = collapsedHeightOpt !== undefined ? collapsedHeightOpt : 0;
  var containerStyles = containerStylesOpt !== undefined ? containerStylesOpt : "";
  var duration = durationOpt !== undefined ? durationOpt : "default";
  var faderColor = faderColorOpt !== undefined ? faderColorOpt : Styles.Color.light01;
  var loadingStyle = loadingStyleOpt !== undefined ? loadingStyleOpt : "lazy";
  var transition = transitionOpt !== undefined ? transitionOpt : "default";
  var withFade = withFadeOpt !== undefined ? withFadeOpt : true;
  var match = React.useState(function (param) {
        if (loadingStyle === "eager" || collapsedHeight > 0) {
          return true;
        } else {
          return !collapsed;
        }
      });
  var setLoaded = match[1];
  var loaded = match[0];
  React.useEffect((function (param) {
          if (!loaded && !collapsed) {
            Curry._1(setLoaded, (function (param) {
                    return true;
                  }));
          }
          
        }), [collapsed]);
  Hooks.useDidUpdate1((function (param) {
          Belt_Option.forEach(onChange, (function (cb) {
                  Curry._1(cb, undefined);
                }));
        }), [collapsed]);
  var match$1 = React.useState(function (param) {
        return collapsed;
      });
  var setHidden = match$1[1];
  var tmp;
  if (loaded) {
    var tmp$1 = {
      animate: collapsed ? "collapsed" : "expanded",
      transition: getTransition(transition, duration),
      hidden: collapsedHeight === 0 && match$1[0],
      initial: "collapsed",
      variants: {
        collapsed: {
          opacity: collapsedHeight,
          height: collapsedHeight.toString() + "px"
        },
        expanded: {
          opacity: 1,
          height: "auto"
        }
      },
      className: container(collapsedHeight > 0, collapsed, faderColor, {
            NAME: "ms",
            VAL: getDurationToMs(duration)
          }, withFade, containerStyles),
      key: Belt_Option.getWithDefault(id, "collapsible"),
      onClick: (function (prim) {
          prim.stopPropagation();
        }),
      children: children,
      onAnimationStart: (function (e) {
          Curry._1(setHidden, (function (param) {
                  return false;
                }));
          Belt_Option.forEach(onAnimationStart, (function (f) {
                  Curry._1(f, e);
                }));
        }),
      onAnimationComplete: (function (e) {
          Belt_Option.forEach(onAnimationComplete, (function (f) {
                  Curry._1(f, e);
                }));
        })
    };
    if (id !== undefined) {
      tmp$1.id = id;
    }
    tmp = React.createElement(React$1.motion.div, tmp$1);
  } else {
    var tmp$2 = {
      hidden: true
    };
    if (id !== undefined) {
      tmp$2.id = Caml_option.valFromOption(id);
    }
    tmp = React.createElement("div", tmp$2);
  }
  return React.createElement(React$1.AnimatePresence, {
              initial: false,
              children: tmp
            });
}

var Collapsible = {
  make: Collapsible$Collapsible
};

function Collapsible$Conditional(Props) {
  var enabled = Props.enabled;
  var collapsed = Props.collapsed;
  var children = Props.children;
  if (enabled) {
    return React.createElement(Collapsible$Collapsible, {
                collapsed: collapsed,
                children: children
              });
  } else {
    return children;
  }
}

var Conditional = {
  make: Collapsible$Conditional
};

var make = Collapsible$Collapsible;

export {
  Style ,
  getDurationToMs ,
  durationToSeconds ,
  getTransition ,
  use ,
  Collapsible ,
  Conditional ,
  make ,
}
/* Css Not a pure module */
