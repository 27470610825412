// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Chunks from "./Chunks.mjs";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Models from "./Models.mjs";
import * as Printer from "../../model/src/Printer.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as ArrayExt from "./ArrayExt.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as ActionUtils from "../../model/src/ActionUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as NamedBranch from "./NamedBranch.mjs";
import * as BranchStateWriter from "./BranchStateWriter.mjs";
import * as App from "firebase/compat/app";
import * as NamespaceIndexWriter from "./NamespaceIndexWriter.mjs";

function wantsToWriteBranchLifecycleActionToMain(actions) {
  return Belt_Array.someU(actions, (function (param) {
                var action = param[0];
                if (typeof action !== "object") {
                  return false;
                }
                var variant = action.NAME;
                if (variant === "CloseBranch" || variant === "OpenBranch" || variant === "CreateDemoBranch") {
                  return true;
                } else {
                  return variant === "MergeBranch";
                }
              }));
}

function snapshot(schemaId, branchId, model, latestAction) {
  var match = latestAction.branchId;
  var tmp;
  if (match !== undefined && match === branchId) {
    var jsonModel = Printer.printModelString(model);
    var versionRef = Firebase.app().firestore().collection("schemas").doc(schemaId).collection("versions").doc();
    var versionId = versionRef.id;
    var versionDocSize = Chunks.getStringByteSize(undefined, jsonModel);
    var isChunked = versionDocSize > 524288;
    if (isChunked) {
      var chunks = Chunks.split(524288, jsonModel);
      var version = {
        id: versionId,
        createdAt: App.default.firestore.FieldValue.serverTimestamp(),
        lastActionId: latestAction.id,
        lastActionTimestamp: latestAction.createdAt,
        branchId: branchId,
        chunks: true,
        chunksCount: chunks.length
      };
      var chunksCollection = versionRef.collection("chunks");
      var batch = Firebase.app().firestore().batch();
      batch.set(versionRef, version);
      if (versionDocSize < 10000000) {
        Belt_Array.forEachWithIndexU(chunks, (function (index, shardString) {
                var shard = {
                  index: index,
                  contentsStr: shardString
                };
                var shardRef = chunksCollection.doc(String(index));
                batch.set(shardRef, shard);
              }));
        tmp = batch.commit();
      } else {
        tmp = Promise.reject(Js_exn.raiseError("Provided tracking plan is too large. Try to import in chunks."));
      }
    } else {
      var version$1 = {
        id: versionId,
        contentsStr: jsonModel,
        createdAt: App.default.firestore.FieldValue.serverTimestamp(),
        lastActionId: latestAction.id,
        lastActionTimestamp: latestAction.createdAt,
        branchId: branchId,
        chunks: false,
        chunksCount: 0
      };
      tmp = versionRef.set(version$1);
    }
  } else {
    tmp = Promise.resolve();
  }
  return $$Promise.$$catch(tmp, (function (error) {
                return Promise.resolve((console.error("Could not write new snapshot", error), undefined));
              }));
}

function buildAction(timestampOpt, action, schemaId, branchId, userId, context, isCreateDemoBranchAutoAction, audit, order) {
  var timestamp = timestampOpt !== undefined ? Caml_option.valFromOption(timestampOpt) : App.default.firestore.FieldValue.serverTimestamp();
  var actionJson = Printer.printAction(action);
  var newActionRef = Firebase.app().firestore().collection("schemas").doc(schemaId).collection("actions").doc();
  var actionId = newActionRef.id;
  var actionObject = {
    id: actionId,
    contentsJson: actionJson,
    createdAt: timestamp,
    createdBy: userId,
    context: context,
    branchId: branchId,
    audit: audit,
    actionImpactLevel: ActionUtils.getActionImpactLevelFromAction(action),
    order: order,
    isCreateDemoBranchAutoAction: isCreateDemoBranchAutoAction
  };
  return [
          newActionRef,
          actionObject,
          actionId
        ];
}

function getWriteOpsForActions(actions, schemaId, branchId, userId, audit, isCreateDemoBranchAutoAction) {
  return Belt_Array.concatMany(Belt_Array.mapWithIndexU(actions, (function (index, param) {
                    var action = param[0];
                    var match = buildAction(undefined, action, schemaId, branchId, userId, param[1], isCreateDemoBranchAutoAction, audit, index);
                    var actionId = match[2];
                    var actionObject = match[1];
                    var maybeNotificationTriggersWrite;
                    if (typeof action === "object") {
                      var variant = action.NAME;
                      if (variant === "CollaboratorRemoved" || variant === "CloseBranch" || variant === "PropertyComment" || variant === "ReviewerAdded" || variant === "GoalComment" || variant === "IntegrationComment" || variant === "InspectorIssueComment" || variant === "MetricComment" || variant === "CollaboratorAdded" || variant === "PropertyGroupComment" || variant === "EventComment" || variant === "OpenBranch" || variant === "BranchComment" || variant === "ReviewerRemoved" || variant === "SetBranchStatus" || variant === "MergeBranch") {
                        var notificationTriggerRef = Firebase.app().firestore().collection("schemas").doc(schemaId).collection("notificationTriggers").doc(actionId);
                        maybeNotificationTriggersWrite = [
                          notificationTriggerRef,
                          actionObject
                        ];
                      } else {
                        maybeNotificationTriggersWrite = undefined;
                      }
                    } else {
                      maybeNotificationTriggersWrite = undefined;
                    }
                    var maybeIntegrationTriggersWrite;
                    if (typeof action === "object" && action.NAME === "MergeBranch") {
                      var integrationTriggerRef = Firebase.app().firestore().collection("schemas").doc(schemaId).collection("integrationTriggers").doc(actionId);
                      maybeIntegrationTriggersWrite = [
                        integrationTriggerRef,
                        actionObject
                      ];
                    } else {
                      maybeIntegrationTriggersWrite = undefined;
                    }
                    var maybeActionWrite = [
                      match[0],
                      actionObject
                    ];
                    return Belt_Array.keepMap([
                                maybeNotificationTriggersWrite,
                                maybeIntegrationTriggersWrite,
                                maybeActionWrite
                              ], (function (a) {
                                  return a;
                                }));
                  })));
}

function writeToFirebase(userId, schemaId, currentBranch, toBranch, isCreateDemoBranchAutoAction, numberOfActions, numberOfMasterActions, latestAction, latestMasterAction, model, mappedModel, masterModel, mappedMasterModel, audit, batch, batchSizeOpt, actions) {
  var batchSize = batchSizeOpt !== undefined ? batchSizeOpt : 200;
  if (Belt_Option.isSome(batch) && actions.length > 100) {
    Pervasives.failwith("Actions.re: Can't write more than 100 actions to a custom batch");
  }
  var writeBatchWithActionsToFirestore = function (writeOps) {
    var match = Belt_Array.reduce(ArrayExt.splitIntoChunks(writeOps, batchSize), [
          [],
          0
        ], (function (param, writeOps) {
            var batch$1 = Belt_Option.getWithDefault(batch, Firebase.app().firestore().batch());
            Belt_Array.forEachU(writeOps, (function (param) {
                    batch$1.set(param[0], param[1]);
                  }));
            return [
                    Belt_Array.concat(param[0], [batch$1]),
                    param[1] + actions.length | 0
                  ];
          }));
    return Belt_Array.reduce(match[0], Promise.resolve(), (function (promise, batch) {
                  return promise.then(function (param) {
                              return batch.commit();
                            });
                }));
  };
  var writeTransactionWithBranchStateUpdateToFirestore = function (branchId, writeOps) {
    return BranchStateWriter.writeBranchStateToFirestoreFromActionsInTransaction(schemaId, branchId, NamedBranch.unnamedBranchToId(currentBranch), actions, userId, model, mappedModel, masterModel, mappedMasterModel, writeOps);
  };
  var branchId = NamedBranch.unnamedBranchToId(toBranch);
  var writeOps = getWriteOpsForActions(actions, schemaId, branchId, userId, audit, isCreateDemoBranchAutoAction);
  var match = BranchStateWriter.wantsToUpdateBranchStatus(actions);
  var match$1 = NamespaceIndexWriter.Web.wantsToUpdateNamespaceIndex(branchId, NamedBranch.unnamedBranchToId(currentBranch), actions);
  return (
            currentBranch === "Master" ? (
                match ? writeTransactionWithBranchStateUpdateToFirestore(branchId, writeOps) : writeBatchWithActionsToFirestore(writeOps)
              ) : (
                match || match$1 ? writeTransactionWithBranchStateUpdateToFirestore(branchId, writeOps) : writeBatchWithActionsToFirestore(writeOps).then(function (param) {
                        return writeTransactionWithBranchStateUpdateToFirestore(branchId, []);
                      })
              )
          ).then(function (param) {
              if (typeof toBranch === "object") {
                var toBranchId = toBranch.VAL;
                if (toBranchId !== NamedBranch.unnamedBranchToId(currentBranch)) {
                  return Promise.resolve();
                }
                var maxNumOfActionsExceeded = numberOfActions > 100 && numberOfActions % 10 === 0;
                if (!maxNumOfActionsExceeded) {
                  return Promise.resolve();
                }
                var match = Belt_Option.flatMap(latestAction, (function (action) {
                        return Models.toDateOption(action.createdAt);
                      }));
                if (latestAction !== undefined && match !== undefined) {
                  return snapshot(schemaId, toBranchId, model, Caml_option.valFromOption(latestAction));
                } else {
                  return Promise.resolve();
                }
              }
              var maxNumOfMasterActionsExceeded = numberOfMasterActions > 100 && (numberOfMasterActions % 10 === 0 || Belt_Array.some(actions, (function (action) {
                        var match = action[0];
                        if (typeof match === "object") {
                          return match.NAME === "MergeBranch";
                        } else {
                          return false;
                        }
                      }))) && !Belt_Array.some(actions, (function (action) {
                      var match = action[0];
                      if (typeof match === "object") {
                        return match.NAME === "OpenBranch";
                      } else {
                        return false;
                      }
                    }));
              if (!maxNumOfMasterActionsExceeded) {
                return Promise.resolve();
              }
              var match$1 = Belt_Option.flatMap(latestMasterAction, (function (action) {
                      return Models.toDateOption(action.createdAt);
                    }));
              if (latestMasterAction !== undefined && match$1 !== undefined) {
                return snapshot(schemaId, "master", masterModel, Caml_option.valFromOption(latestMasterAction));
              } else {
                return Promise.resolve();
              }
            });
}

export {
  wantsToWriteBranchLifecycleActionToMain ,
  snapshot ,
  buildAction ,
  getWriteOpsForActions ,
  writeToFirebase ,
}
/* Models Not a pure module */
