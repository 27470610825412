// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as $$String from "rescript/lib/es6/string.js";
import * as Nanoid from "nanoid";
import * as Domains from "../../model/src/domains/Domains.mjs";
import * as Zustand from "./Zustand.mjs";
import * as Zustand$1 from "zustand";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as UsersStore from "./UsersStore.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DomainUtils from "../../model/src/domains/DomainUtils.mjs";
import * as DomainMembers from "../../model/src/domains/DomainMembers.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as DomainWebUtils from "./DomainWebUtils.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";

var StoreConfig = {};

var ZustandStore = Zustand.MakeStore(StoreConfig);

var store = Zustand$1.create(function (set, _get) {
      return {
              initialized: false,
              initialize: (function (param) {
                  set(function (state) {
                        return {
                                initialized: true,
                                initialize: state.initialize,
                                membersInitialized: state.membersInitialized,
                                initializeMembers: state.initializeMembers,
                                subscribers: state.subscribers,
                                subscribe: state.subscribe,
                                unsubscribe: state.unsubscribe,
                                domains: state.domains,
                                setDomains: state.setDomains,
                                members: state.members,
                                memberDomainIds: state.memberDomainIds,
                                setMembers: state.setMembers,
                                events: state.events,
                                eventVariants: state.eventVariants
                              };
                      });
                }),
              membersInitialized: false,
              initializeMembers: (function (param) {
                  set(function (state) {
                        return {
                                initialized: state.initialized,
                                initialize: state.initialize,
                                membersInitialized: true,
                                initializeMembers: state.initializeMembers,
                                subscribers: state.subscribers,
                                subscribe: state.subscribe,
                                unsubscribe: state.unsubscribe,
                                domains: state.domains,
                                setDomains: state.setDomains,
                                members: state.members,
                                memberDomainIds: state.memberDomainIds,
                                setMembers: state.setMembers,
                                events: state.events,
                                eventVariants: state.eventVariants
                              };
                      });
                }),
              subscribers: [],
              subscribe: (function (subscribeId) {
                  set(function (state) {
                        return {
                                initialized: state.initialized,
                                initialize: state.initialize,
                                membersInitialized: state.membersInitialized,
                                initializeMembers: state.initializeMembers,
                                subscribers: Belt_SetString.toArray(Belt_SetString.add(Belt_SetString.fromArray(state.subscribers), subscribeId)),
                                subscribe: state.subscribe,
                                unsubscribe: state.unsubscribe,
                                domains: state.domains,
                                setDomains: state.setDomains,
                                members: state.members,
                                memberDomainIds: state.memberDomainIds,
                                setMembers: state.setMembers,
                                events: state.events,
                                eventVariants: state.eventVariants
                              };
                      });
                }),
              unsubscribe: (function (subscribeId) {
                  set(function (state) {
                        return {
                                initialized: state.initialized,
                                initialize: state.initialize,
                                membersInitialized: state.membersInitialized,
                                initializeMembers: state.initializeMembers,
                                subscribers: Belt_SetString.toArray(Belt_SetString.remove(Belt_SetString.fromArray(state.subscribers), subscribeId)),
                                subscribe: state.subscribe,
                                unsubscribe: state.unsubscribe,
                                domains: state.domains,
                                setDomains: state.setDomains,
                                members: state.members,
                                memberDomainIds: state.memberDomainIds,
                                setMembers: state.setMembers,
                                events: state.events,
                                eventVariants: state.eventVariants
                              };
                      });
                }),
              domains: Domains.empty,
              setDomains: (function (domains) {
                  set(function (state) {
                        return {
                                initialized: state.initialized,
                                initialize: state.initialize,
                                membersInitialized: state.membersInitialized,
                                initializeMembers: state.initializeMembers,
                                subscribers: state.subscribers,
                                subscribe: state.subscribe,
                                unsubscribe: state.unsubscribe,
                                domains: domains,
                                setDomains: state.setDomains,
                                members: state.members,
                                memberDomainIds: state.memberDomainIds,
                                setMembers: state.setMembers,
                                events: DomainUtils.keyByEventIds(domains),
                                eventVariants: DomainUtils.keyByEventVariantIds(domains)
                              };
                      });
                }),
              members: DomainMembers.empty,
              memberDomainIds: undefined,
              setMembers: (function (members) {
                  set(function (state) {
                        return {
                                initialized: state.initialized,
                                initialize: state.initialize,
                                membersInitialized: state.membersInitialized,
                                initializeMembers: state.initializeMembers,
                                subscribers: state.subscribers,
                                subscribe: state.subscribe,
                                unsubscribe: state.unsubscribe,
                                domains: state.domains,
                                setDomains: state.setDomains,
                                members: Curry._1(DomainMembers.fromArray, members),
                                memberDomainIds: Belt_Array.reduceU(members, undefined, (function (domainMembersMap, member) {
                                        return Belt_MapString.updateU(domainMembersMap, member.id, (function (maybeDomainIds) {
                                                      if (maybeDomainIds !== undefined) {
                                                        return Caml_option.some(Belt_SetString.add(Caml_option.valFromOption(maybeDomainIds), member.domainId));
                                                      } else {
                                                        return Caml_option.some(Belt_SetString.fromArray([member.domainId]));
                                                      }
                                                    }));
                                      })),
                                setMembers: state.setMembers,
                                events: state.events,
                                eventVariants: state.eventVariants
                              };
                      });
                }),
              events: undefined,
              eventVariants: undefined
            };
    });

function useIsInitialized(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.initialized;
              }));
}

function _use(param) {
  var schemaId = WorkspaceContext.use().id;
  var subscribeId = React.useRef(Nanoid.nanoid());
  var subscribe = Curry._2(ZustandStore.use, store, (function (state) {
          return state.subscribe;
        }));
  var unsubscribe = Curry._2(ZustandStore.use, store, (function (state) {
          return state.unsubscribe;
        }));
  var subscribers = Curry._2(ZustandStore.use, store, (function (state) {
          return state.subscribers;
        }));
  var setDomains = Curry._2(ZustandStore.use, store, (function (state) {
          return state.setDomains;
        }));
  var setMembers = Curry._2(ZustandStore.use, store, (function (state) {
          return state.setMembers;
        }));
  var initialize = Curry._2(ZustandStore.use, store, (function (state) {
          return state.initialize;
        }));
  var initializeMembers = Curry._2(ZustandStore.use, store, (function (state) {
          return state.initializeMembers;
        }));
  React.useEffect((function (param) {
          Curry._1(subscribe, subscribeId.current);
          return (function (param) {
                    Curry._1(unsubscribe, subscribeId.current);
                  });
        }), []);
  var id = Belt_Array.get(subscribers, 0);
  var isMainSubscriber = id !== undefined ? id === subscribeId.current : false;
  var mode = React.useMemo((function (param) {
          if (isMainSubscriber) {
            return "Fetch";
          } else {
            return "Skip";
          }
        }), [isMainSubscriber]);
  var domainsFromFirebase = DomainWebUtils.Firebase.useDomains(mode, schemaId);
  var membersFromFirebase = DomainWebUtils.Firebase.useAllDomainMembers(mode, schemaId);
  React.useEffect((function (param) {
          if (typeof domainsFromFirebase === "object" && domainsFromFirebase.NAME === "Loaded" && mode === "Fetch") {
            Curry._1(setDomains, Curry._1(Domains.fromArray, Belt_SortArray.stableSortBy(domainsFromFirebase.VAL, (function (a, b) {
                            return $$String.compare(a.name, b.name);
                          }))));
            Curry._1(initialize, undefined);
          }
          
        }), [
        domainsFromFirebase,
        mode
      ]);
  React.useEffect((function (param) {
          if (typeof membersFromFirebase === "object" && membersFromFirebase.NAME === "Loaded" && mode === "Fetch") {
            Curry._1(setMembers, membersFromFirebase.VAL);
            Curry._1(initializeMembers, undefined);
          }
          
        }), [
        membersFromFirebase,
        mode
      ]);
}

function useDomains(param) {
  _use();
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.domains;
              }));
}

function useDomain(domainId) {
  var domains = useDomains();
  return React.useMemo((function (param) {
                return Curry._2(Domains.get, domains, domainId);
              }), [
              domains,
              domainId
            ]);
}

function useDomainEvents(param) {
  _use();
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.events;
              }));
}

function useDomainEventVariants(param) {
  _use();
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.eventVariants;
              }));
}

function useEventDomains(eventId) {
  var events = useDomainEvents();
  return React.useMemo((function (param) {
                return Belt_MapString.getWithDefault(events, eventId, Domains.empty);
              }), [
              events,
              eventId
            ]);
}

function useEventVariantDomains(eventVariantId) {
  var eventVariants = useDomainEventVariants();
  return React.useMemo((function (param) {
                return Belt_MapString.getWithDefault(eventVariants, eventVariantId, Domains.empty);
              }), [
              eventVariants,
              eventVariantId
            ]);
}

function useEventOrEventVariantDomains(eventId, isVariant) {
  var events = useDomainEvents();
  var eventVariants = useDomainEventVariants();
  var items = isVariant ? eventVariants : events;
  return React.useMemo((function (param) {
                return Belt_MapString.getWithDefault(items, eventId, Domains.empty);
              }), [
              items,
              eventId
            ]);
}

function useMembersInitialized(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.membersInitialized;
              }));
}

function useDomainMembers(forDomainId, param) {
  _use();
  var allDomainMembers = Curry._2(ZustandStore.use, store, (function (state) {
          return state.members;
        }));
  return React.useMemo((function (param) {
                if (forDomainId !== undefined) {
                  return Curry._2(DomainMembers.keep, allDomainMembers, (function (param) {
                                return param.domainId === forDomainId;
                              }));
                } else {
                  return allDomainMembers;
                }
              }), [
              allDomainMembers,
              forDomainId
            ]);
}

function useDomainMemberViewModels(forDomainId, param) {
  var domainMembers = useDomainMembers(forDomainId, undefined);
  var theirIds = React.useMemo((function (param) {
          return Belt_SetString.toArray(Belt_SetString.fromArray(DomainMembers.getUserIds(domainMembers)));
        }), [
        domainMembers,
        forDomainId
      ]);
  var users = UsersStore.use(theirIds);
  var usersMap = React.useMemo((function (param) {
          if (typeof users === "object" && users.NAME !== "Errored") {
            var users$1 = users.VAL;
            return Belt_MapString.fromArray(Belt_Array.mapU(users$1, (function (user) {
                              return [
                                      user.id,
                                      user
                                    ];
                            })));
          }
          
        }), [users]);
  return React.useMemo((function (param) {
                return Curry._2(DomainMembers.keepMap, domainMembers, (function (member) {
                              var match = Belt_MapString.get(usersMap, member.id);
                              if (match !== undefined) {
                                return {
                                        id: member.id,
                                        role: member.role,
                                        name: Caml_option.nullable_to_opt(match.name),
                                        email: Caml_option.nullable_to_opt(match.email)
                                      };
                              }
                              
                            }));
              }), [
              forDomainId,
              users,
              domainMembers
            ]);
}

function useMemberDomains(userId) {
  var domains = useDomains();
  var memberDomainIds = Curry._2(ZustandStore.use, store, (function (state) {
          return state.memberDomainIds;
        }));
  return React.useMemo((function (param) {
                var domainIds = Belt_MapString.get(memberDomainIds, userId);
                if (domainIds === undefined) {
                  return Domains.empty;
                }
                var domainIds$1 = Caml_option.valFromOption(domainIds);
                return Curry._2(Domains.keep, domains, (function (param) {
                              return Belt_SetString.has(domainIds$1, param.id);
                            }));
              }), [
              userId,
              memberDomainIds,
              domains
            ]);
}

function useDomainWithArchive(domainId) {
  var schemaId = WorkspaceContext.use().id;
  return DomainWebUtils.Firebase.useDomain(schemaId, domainId);
}

function useEventsAndEventVariants(param) {
  var events = Curry._2(ZustandStore.use, store, (function (state) {
          return state.events;
        }));
  var eventVariants = Curry._2(ZustandStore.use, store, (function (state) {
          return state.eventVariants;
        }));
  return React.useMemo((function (param) {
                return Belt_MapString.mergeMany(events, Belt_MapString.toArray(eventVariants));
              }), [
              events,
              eventVariants
            ]);
}

export {
  StoreConfig ,
  ZustandStore ,
  store ,
  useIsInitialized ,
  useDomains ,
  useDomain ,
  useDomainEvents ,
  useDomainEventVariants ,
  useEventDomains ,
  useEventVariantDomains ,
  useEventOrEventVariantDomains ,
  useMembersInitialized ,
  useDomainMembers ,
  useDomainMemberViewModels ,
  useMemberDomains ,
  useDomainWithArchive ,
  useEventsAndEventVariants ,
}
/* ZustandStore Not a pure module */
