// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as React from "react";
import * as Nanoid from "nanoid";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RouterStore from "./RouterStore.mjs";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as ReactQuery from "@tanstack/react-query";
import * as Belt_MutableSetString from "rescript/lib/es6/belt_MutableSetString.js";

function useUsersCleanup(param) {
  var schemaId = Belt_Option.getWithDefault(RouterStore.Schema.useSchemaIdSafe(), "no-schema");
  var queryClient = ReactQuery.useQueryClient();
  Hooks.useDidUpdate1((function (param) {
          queryClient.removeQueries({
                queryKey: ["users"],
                exact: false
              });
        }), [schemaId]);
}

function use(ids) {
  useUsersCleanup();
  var hookId = React.useRef("many-" + Nanoid.nanoid());
  var queryClient = ReactQuery.useQueryClient();
  var collection = Firebase.app().firestore().collection("users");
  var userId = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app().auth().currentUser)).uid;
  var fetchUser = React.useCallback((function (id) {
          return collection.doc(id).get().then(function (snapshot) {
                      if (snapshot.exists) {
                        return {
                                state: "Success",
                                _0: snapshot.data()
                              };
                      } else {
                        return {
                                state: "NotFound",
                                _0: id
                              };
                      }
                    });
        }), []);
  React.useEffect((function (param) {
          return (function (param) {
                    queryClient.removeQueries({
                          queryKey: [
                            "users",
                            hookId.current
                          ],
                          exact: true
                        });
                  });
        }), []);
  var match = React.useMemo((function (param) {
          var idsSet = Belt_SetString.fromArray(ids);
          var existingData = queryClient.getQueriesData({
                queryKey: ["users"],
                exact: false
              });
          var existingIds = Belt_MutableSetString.fromArray([]);
          var existingData$1 = Belt_Array.keepMapU(existingData, (function (param) {
                  var result = param[1];
                  var queryKey = param[0];
                  if (typeof queryKey === "string") {
                    return ;
                  }
                  if (queryKey.length !== 2) {
                    return ;
                  }
                  var match = queryKey[0];
                  if (match !== "users") {
                    return ;
                  }
                  var id = queryKey[1];
                  if (id === hookId.current || !(Belt_SetString.has(idsSet, id) && result !== undefined && result.state === "Success" && id !== userId)) {
                    return ;
                  } else {
                    Belt_MutableSetString.add(existingIds, id);
                    return result._0;
                  }
                }));
          var missingIds = Belt_Array.keepU(ids, (function (id) {
                  return !Belt_MutableSetString.has(existingIds, id);
                }));
          return [
                  existingData$1,
                  missingIds
                ];
        }), [
        queryClient,
        ids
      ]);
  var missingIds = match[1];
  var existingData = match[0];
  var fetchAll = React.useCallback((function (ids, param) {
          return Promise.all(Belt_Array.mapU(ids, Curry.__1(fetchUser)));
        }), []);
  var match$1 = ReactQuery.useQuery({
        queryKey: [
          "users",
          hookId.current
        ],
        queryFn: Curry._1(fetchAll, missingIds),
        enabled: missingIds.length !== 0,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: 0.0
      });
  var isPending = match$1.isPending;
  var isError = match$1.isError;
  var failureReason = match$1.failureReason;
  var data = match$1.data;
  return React.useMemo((function (param) {
                if (missingIds.length === 0) {
                  return {
                          NAME: "Loaded",
                          VAL: existingData
                        };
                }
                if (data === undefined) {
                  if (failureReason !== undefined && isError) {
                    return {
                            NAME: "Errored",
                            VAL: Caml_option.valFromOption(failureReason)
                          };
                  } else {
                    return "Loading";
                  }
                }
                if (isPending) {
                  return "Loading";
                }
                var users = Belt_Array.keepMapU(Caml_option.valFromOption(data), (function (result) {
                        if (result.state !== "Success") {
                          return ;
                        }
                        var user = result._0;
                        queryClient.setQueryData([
                              "users",
                              user.id
                            ], {
                              state: "Success",
                              _0: user
                            });
                        return user;
                      }));
                return {
                        NAME: "Loaded",
                        VAL: Belt_Array.concatMany([
                              existingData,
                              users
                            ])
                      };
              }), [
              data,
              existingData,
              failureReason,
              isError
            ]);
}

function useUser(id) {
  useUsersCleanup();
  var user = use([id]);
  return React.useMemo((function (param) {
                if (typeof user !== "object") {
                  return "Loading";
                }
                if (user.NAME === "Errored") {
                  return "NotFound";
                }
                var arr = user.VAL;
                if (arr.length !== 1) {
                  var user$1 = Belt_Array.get(arr, 0);
                  if (user$1 !== undefined) {
                    return {
                            NAME: "Success",
                            VAL: user$1
                          };
                  } else {
                    return "Loading";
                  }
                }
                var user$2 = arr[0];
                return {
                        NAME: "Success",
                        VAL: user$2
                      };
              }), [user]);
}

function useAll(param) {
  useUsersCleanup();
  var members = WorkspaceContext.use().members;
  return use(React.useMemo((function (param) {
                    return Belt_Array.mapU(Belt_List.toArray(members), (function (member) {
                                  return member.id;
                                }));
                  }), [members]));
}

export {
  use ,
  useUser ,
  useAll ,
}
/* Hooks Not a pure module */
